import React from "react";
import { Transition, TransitionGroup } from "react-transition-group";
import PropTypes from "prop-types";
import GlobalStyle from "styles";
import {
  LayoutWrapper,
  PageWrapper,
  ContentWrapper,
  Main,
} from "./index.styled";
import { gsap, ScrollTrigger } from "helpers/gsap";
import Header from "components/header";
import Footer from "components/footer";
import "styles/fonts.css";

import { HeaderProvider } from "context";

const LayoutContent = ({ children, location }) => {
  return (
    <TransitionGroup component={null}>
      <Transition key={location.pathname} timeout={{ enter: 1000, exit: 500 }}>
        {(status) => (
          <div className={`page ${status}`}>
            <LayoutWrapper id="layoutWrapper">{children}</LayoutWrapper>
          </div>
        )}
      </Transition>
    </TransitionGroup>
  );
};

const LayoutContainer = ({ children, location }) => {
  const refWrapper = React.useRef(null);
  let scrollerSmoother;
  React.useLayoutEffect(() => {
    let gsapContext = gsap.context(() => {
      // scrollerSmoother = ScrollSmoother.create({
      //   content: ".home-content",
      //   wrapper: ".home-wrapper",
      //   smooth: 1.5,
      // });

      ScrollTrigger.normalizeScroll({ allowNestedScroll: true });
    }, refWrapper);

    return () => gsapContext.revert();
  }, [children]);

  React.useEffect(() => {
    if (scrollerSmoother && location.hash) {
      const screenWidth = window.innerWidth;
      setTimeout(() => {
        gsap.to(scrollerSmoother, {
          scrollTop: document?.querySelector(`${location.hash}`)?.offsetTop,
          duration: screenWidth > 768 ? 2 : -1,
        });
      }, 100);
    }
  }, [scrollerSmoother, location.hash]);

  return (
    <Main $contact={location.pathname === "/contact/" || location.pathname === "/thankyou/" || location.pathname === "/contact" || location.pathname === "/thankyou"}>
      <Header className={location.pathname === "/" && "home"} />
      <div className="home-wrapper" ref={refWrapper}>
        <div className="home-content">
          <PageWrapper>
            <GlobalStyle />
            <ContentWrapper>
              <LayoutContent location={location}>{children}</LayoutContent>
            </ContentWrapper>
          </PageWrapper>
          {location.pathname !== "/legal/" && (
            <Footer location={location.pathname} />
          )}
        </div>
      </div>
    </Main>
  );
};

const Layout = (props) => {
  return (
    <HeaderProvider>
      <LayoutContainer {...props} />
    </HeaderProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.object.isRequired,
};

export default Layout;
