import styled from "styled-components";
import { calculateResponsiveSize } from "helpers/calculateResponsiveSize";
import { mediaMax } from "helpers/mediaQueries";
import { Link } from "gatsby";
import BGGradient from "icons/footer-gradient.svg";
import HouseLogoWhite from "icons/house-logo-white.svg";
import HinesLogoWhite from "icons/hines-logo-white.svg";
import TCCLogoWhite from "icons/tcc-logo-white.svg";

export const Wrapper = styled.div`
  width: 100%;
  color: #fff;
`;

export const Copyright = styled.span`
  /* font-weight: 600; */
  font-size: ${calculateResponsiveSize(14)};
  line-height: 1.2;
  font-family: "proxima_nova_light", san-serif;
  white-space: pre-wrap;
  ${mediaMax("desktopSm")} {
    font-size: 12px;
  }
  ${mediaMax("tabletLandscape")} {
    margin: 24px 0;
  }
`;

export const LegalLink = styled(Link)`
  display: flex;
  align-items: center;
  margin-right: ${calculateResponsiveSize(36)};
  font-family: "proxima_nova_semibold", san-serif;
  font-size: ${calculateResponsiveSize(18)};
  /* font-weight: 600; */
  letter-spacing: 1.8px;
  text-transform: uppercase;
  &:before {
    content: "";
    display: block;
    margin-right: ${calculateResponsiveSize(24)};
    width: ${calculateResponsiveSize(31)};
    height: ${calculateResponsiveSize(30)};
    background: url(${HouseLogoWhite}) no-repeat center;
    background-size: contain;
  }
  ${mediaMax("desktopSm")} {
    font-size: 14px;
    letter-spacing: 1.4px;
    &:before {
      margin-right: 24px;
      width: 31px;
      height: 30px;
    }
  }
`;
export const Logos = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: ${calculateResponsiveSize(24)};
  ${mediaMax("desktopSm")} {
    margin: 24px 0;
  }
`;
export const LogoTCC = styled.span`
  display: block;
  width: ${calculateResponsiveSize(95)};
  height: ${calculateResponsiveSize(29)};
  background: url(${TCCLogoWhite}) no-repeat center;
  background-size: contain;
  ${mediaMax("desktopSm")} {
    width: 78px;
    height: 24px;
  }
`;
export const LogoHines = styled.span`
  margin-right: ${calculateResponsiveSize(24)};
  display: block;
  width: ${calculateResponsiveSize(100)};
  height: ${calculateResponsiveSize(30)};
  background: url(${HinesLogoWhite}) no-repeat center;
  background-size: contain;
  ${mediaMax("desktopSm")} {
    margin-right: 24px;
    width: 80px;
    height: 24px;
  }
`;
export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  &:last-child {
    border-top: ${calculateResponsiveSize(1)} solid #fff;
    padding-top: ${calculateResponsiveSize(24)};
  }
  ${mediaMax("tabletLandscape")} {
    flex-direction: column;
  }
`;
