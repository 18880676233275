import React from "react";
import CopyrightAndLegal from "components/ui/copyright-and-legal";
import {
  Wrapper,
  Title,
  Description,
  Copy,
  Info,
  InfoColumn,
  InfoTitle,
  InfoLink,
  Row,
  SocialLink,
  InfoText,
} from "./index.styled";
import Button from "components/ui/button";
import { useStaticQuery, graphql } from "gatsby";

const Footer = ({ className, location }) => {
  const data = useStaticQuery(graphql`
    query FooterQuery {
      footerContent {
        content {
          adress
          adressTitle
          copyright
          description
          legalLink {
            text
            url
          }
          link {
            linkText
            linkUrl
          }
          mail
          phone {
            phoneItem
            phoneText
          }
          contactTitle
          socilaLink {
            linkText
            linkUrl
          }
          title
        }
      }
    }
  `);

  const {
    title,
    description,
    link,
    contactTitle,
    mail,
    phone,
    adressTitle,
    adress,
    socilaLink,
  } = data.footerContent.content[0];
  return (
    <Wrapper className={className} $location={location}>
      <Row $location={location}>
        <Copy>
          <Title>{title}</Title>
          <Description>{description}</Description>
          <Button link={link.linkUrl}>{link.linkText}</Button>
        </Copy>
        <Info>
          <InfoColumn>
            <InfoTitle>{contactTitle}</InfoTitle>
            <InfoLink target="_blank" href={`tel:${phone.phoneItem}`}>
              {phone.phoneText}
            </InfoLink>
            <InfoLink target="_blank" href={`mailto:${mail}`}>
              {mail}
            </InfoLink>
            <SocialLink href={socilaLink.linkUrl} target="_blank" />
          </InfoColumn>
          <InfoColumn>
            <InfoTitle>{adressTitle}</InfoTitle>
            <InfoLink href="#">
              <InfoText>{adress}</InfoText>
            </InfoLink>
          </InfoColumn>
        </Info>
      </Row>
      <CopyrightAndLegal />
    </Wrapper>
  );
};

export default Footer;
