import React from "react";
import { ButtonDefault, LinkButton } from "./index.styled";

const Button = ({ children, link, onClick, theme, mobileTheme }) => {
  return (
    <>
      {link ? (
        <LinkButton to={link}>{children}</LinkButton>
      ) : (
        <ButtonDefault
          $mobileTheme={mobileTheme}
          $theme={theme}
          onClick={onClick}
        >
          {children}
        </ButtonDefault>
      )}
    </>
  );
};

export default Button;
