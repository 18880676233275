import React, { useEffect, useState } from "react";
import CopyrightAndLegal from "components/ui/copyright-and-legal";
import {
  Wrapper,
  LogoWrapper,
  Logo,
  Nav,
  MenuButtonWrapper,
  Footer,
  Copyright,
  Legal,
  LegalLink,
  SocialLink,
  NavItemWrapper,
  NavSublinks,
  NavItemLink,
  NavItemSublink,
  NavItemSubAnchor,
  AdditionalMenu,
  AdditionalLink,
  NavItemSublinkInner,
  NavItemSublinkDivider,
  AnimatimateBackground,
} from "./index.styled";
import CloseButton from "components/ui/close-button";
import { Player } from "@lottiefiles/react-lottie-player";

const Menu = ({ onClick, data }) => {
  const [scrollbarWidth, setScrollbarWidth] = useState(0);
  const [activeSublinkIndex, setActiveSublinkIndex] = useState(null);

  const toggleSublinks = (index) => {
    setActiveSublinkIndex((prevIndex) => (prevIndex === index ? null : index));
  };
  useEffect(() => {
    const div = document.createElement("div");
    div.style.width = "100px";
    div.style.height = "100px";
    div.style.overflow = "scroll";
    div.style.visibility = "hidden";
    div.style.msOverflowStyle = "scrollbar";
    document.body.appendChild(div);
    setScrollbarWidth(div.offsetWidth - div.clientWidth);
    document.body.removeChild(div);
  }, []);

  return (
    <Wrapper>
      <LogoWrapper onClick={onClick}>
        <Logo to="/" />
      </LogoWrapper>
      <MenuButtonWrapper $scrollBarWidth={scrollbarWidth}>
        <CloseButton onClick={onClick} />
      </MenuButtonWrapper>
      <Nav>
        {data.mainList.map((item, index) => {
          const isSublinksOpen = activeSublinkIndex === index;
          return (
            <NavItemWrapper
              onClick={() => toggleSublinks(index)}
              key={`nav-link-${index}`}
            >
              <React.Fragment>
                <NavItemLink
                  $mobileClickableState={isSublinksOpen}
                  to={item.url}
                  onClick={onClick}
                >
                  {item.text}
                </NavItemLink>

                <NavSublinks className={isSublinksOpen ? "open" : ""}>
                  {item.subItems.map((subItem, index) => {
                    return (
                      <React.Fragment key={`nav-sublink-${index}`}>
                        <NavItemSublink to={subItem.url} onClick={onClick}>
                          <NavItemSublinkInner
                            className={subItem.divider && "show-divider"}
                          >
                            {subItem.text}
                          </NavItemSublinkInner>
                        </NavItemSublink>
                      </React.Fragment>
                    );
                  })}
                </NavSublinks>
              </React.Fragment>
            </NavItemWrapper>
          );
        })}
      </Nav>

      <AdditionalMenu>
        {data.secondList.map((item, index) => {
          return (
            <AdditionalLink
              onClick={onClick}
              key={`additional-nav-link-${index}`}
              to={item.url}
            >
              {item.text}
            </AdditionalLink>
          );
        })}
      </AdditionalMenu>
      <Footer>
        <CopyrightAndLegal onClick={onClick} />
      </Footer>
    </Wrapper>
  );
};

export default Menu;
