import React from "react";

export const HeaderContext = React.createContext({});

export const HeaderProvider = ({ children }) => {
  const [headerState, setHeaderState] = React.useState(null);

  return (
    <HeaderContext.Provider
      value={{
        headerState,
        setHeaderState,
      }}
    >
      {children}
    </HeaderContext.Provider>
  );
};
export const useHeaderColor = () => React.useContext(HeaderContext);
