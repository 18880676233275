import styled from 'styled-components'
import { calculateResponsiveSize } from 'helpers/calculateResponsiveSize'
import { mediaMax } from 'helpers/mediaQueries'
import { Link } from 'gatsby'
import InstagramIcon from 'icons/instagram-icon-white.svg'

export const Wrapper = styled.footer`
  position: relative;
  z-index: 0;
  background: ${({ $location }) =>
    $location === '/contact/' || $location === '/contact' || $location === '/thankyou/' || $location == '/thankyou'
      ? 'transparent'
      : `linear-gradient(0deg,#D77E5D 9.48%, #3F62AE 100%)`};
  background-size: cover;
  color: #fff;

  padding: ${calculateResponsiveSize(60)} ${calculateResponsiveSize(60)}
    ${calculateResponsiveSize(36)};
  ${mediaMax('tabletLandscape')} {
    padding: 48px 20px 48px;
  }
`
export const Title = styled.h3`
  margin-bottom: ${calculateResponsiveSize(24)};
  font-weight: 400;
  font-size: ${calculateResponsiveSize(60)};
  line-height: ${calculateResponsiveSize(72)};
  ${mediaMax('desktopSm')} {
    font-size: 36px;
    line-height: 42px;
    letter-spacing: -0.72px;
  }
  ${mediaMax('tabletLandscape')} {
    margin-bottom: 9px;
  }
`

export const Description = styled.p`
  font-weight: 400;
  font-size: ${calculateResponsiveSize(21)};
  line-height: ${calculateResponsiveSize(36)};
  max-width: ${calculateResponsiveSize(580)};
  ${mediaMax('desktopSm')} {
    font-size: 18px;
    line-height: 1.6;
  }
  ${mediaMax('tabletLandscape')} {
    max-width: 95%;
  }
`

export const Copy = styled.div`
  width: 50%;
  padding-bottom: ${calculateResponsiveSize(90)};
  ${mediaMax('tabletLandscape')} {
    width: 100%;
    padding-bottom: 24px;
  }
`

export const Info = styled.div`
  display: flex;
  width: 50%;
  justify-content: flex-end;
  padding-top: ${calculateResponsiveSize(10)};
  padding-right: ${calculateResponsiveSize(100)};
  ${mediaMax('tabletLandscape')} {
    width: 100%;
    padding: 0 0 24px 0;
  }
  ${mediaMax('tablet')} {
    flex-direction: column;
  }
`

export const InfoColumn = styled.div`
  width: 40%;
  ${mediaMax('desktopSm')} {
    width: 50%;
  }
  ${mediaMax('tablet')} {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 24px;
    &:last-child {
      margin-bottom: 0;
    }
  }
`

export const InfoTitle = styled.h4`
  font-size: ${calculateResponsiveSize(36)};
  margin-bottom: ${calculateResponsiveSize(36)};
  font-weight: 400;
  ${mediaMax('desktopSm')} {
    font-size: 20px;
    margin-bottom: 15px;
    line-height: 1;
    letter-spacing: 0.2px;
  }
`

export const InfoText = styled.span`
  display: block;
  font-weight: 400;
  font-size: ${calculateResponsiveSize(18)};
  line-height: 2;
  white-space: pre-wrap;
  ${mediaMax('desktopSm')} {
    font-size: 18px;
    line-height: 1.6;
  }
`

export const InfoLink = styled.a`
  display: block;
  font-weight: 400;
  font-size: ${calculateResponsiveSize(18)};
  line-height: 2;
  ${mediaMax('desktopSm')} {
    font-size: 18px;
    line-height: 1.6;
  }
  ${mediaMax('tablet')} {
    order: 2;
  }
`

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: ${calculateResponsiveSize(36)};
  border-top: ${({ $location }) =>
    $location === '/contact/' || $location === '/thankyou/' ? '1px solid #fff' : ``};
  ${mediaMax('tabletLandscape')} {
    padding-top: 40px;
    flex-direction: column;
  }
`

export const SocialLink = styled.a`
  display: block;
  margin-top: ${calculateResponsiveSize(8)};
  width: ${calculateResponsiveSize(18)};
  height: ${calculateResponsiveSize(18)};
  background: url(${InstagramIcon}) no-repeat;
  transition: opacity 0.5s;
  background-size: cover;
  cursor: pointer;
  &:hover {
    opacity: 0.5;
  }
  ${mediaMax('desktopSm')} {
    width: 18px;
    height: 18px;
  }
  ${mediaMax('tablet')} {
    order: 1;
    margin-bottom: 15px;
  }
`
