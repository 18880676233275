import styled from "styled-components";
import { calculateResponsiveSize } from "helpers/calculateResponsiveSize";
import { mediaMax } from "helpers/mediaQueries";

import CloseWhiteIcon from "icons/close-icon-white.svg";
import CloseBlackIcon from "icons/close-icon-black.svg";

export const Button = styled.button`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${calculateResponsiveSize(84)};
  height: ${calculateResponsiveSize(84)};
  background-color: transparent;
  overflow: hidden;
  border: ${calculateResponsiveSize(1)} #fff solid;
  cursor: pointer;
  &:before {
    content: "";
    position: relative;
    display: block;
    width: ${calculateResponsiveSize(30)};
    height: ${calculateResponsiveSize(30)};
    background-image: url(${CloseWhiteIcon});
    background-position: center;
    background-repeat: no-repeat;
    z-index: 1;
  }
  &:hover {
    &:after {
      width: 100%;
    }
    &:before {
      background-image: url(${CloseBlackIcon});
    }
  }
  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 0;
    background-color: #fff;
    transition: width 0.5s;
  }
  ${mediaMax("desktopSm")} {
    width: 36px;
    height: 36px;
    &:before {
      width: 12px;
      height: 12px;
    }
  }
`;
