import styled, { css } from "styled-components";
import { calculateResponsiveSize } from "helpers/calculateResponsiveSize";
import { mediaMax } from "helpers/mediaQueries";
import { Link } from "gatsby";

export const ButtonStyles = css`
  position: relative;
  margin-top: ${calculateResponsiveSize(36)};
  display: inline-block;
  padding: ${calculateResponsiveSize(10)} ${calculateResponsiveSize(25)};
  font-weight: 500;
  font-size: ${calculateResponsiveSize(14)};
  line-height: 1;
  letter-spacing: 0.1em;
  background: transparent;
  border: ${calculateResponsiveSize(1)} solid #fff;
  color: ${({ $theme }) => ($theme === "dark" ? `#1E1E1E` : `#fff`)};
  border-color: ${({ $theme }) => ($theme === "dark" ? `#1E1E1E` : `#fff`)};
  text-decoration: none;
  transition: color 0.3s ease-in-out;
  font-family: "proxima_nova", san-serif;
  z-index: 1;
  text-transform: uppercase;
  &:hover {
    color: ${({ $theme }) => ($theme === "dark" ? `#fff` : `#000`)};
    &:after {
      width: 100%;
    }
  }
  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 0;
    background-color: ${({ $theme }) =>
      $theme === "dark" ? `#1E1E1E` : `#fff`};
    transition: width 0.5s;
    z-index: -1;
  }
  cursor: pointer;
  ${mediaMax("desktopSm")} {
    margin-top: 36px;
    padding: 10px 25px;
    font-size: 14px;
    letter-spacing: 1.4px;
  }
  ${mediaMax("tablet")} {
    margin-top: 24px;
    padding: 10px 28px;
    font-weight: 600;
    color: #1e1e1e;
    font-size: 14px;
    &:after {
      width: 100%;
    }
    &:hover {
      color: inherit;
    }
    ${({ $mobileTheme }) =>
      $mobileTheme === "outline" &&
      `margin-top: 0;
      padding: 10px 34px;
      color: #fff;
      &:hover {
        color: #fff;
      }
      &:after {
        background: transparent;
      }
  `}
  }
`;

export const LinkButton = styled(Link)`
  ${ButtonStyles};
`;

export const ButtonDefault = styled.button`
  ${ButtonStyles};
`;
