import styled, { css } from "styled-components";
import { Link } from "gatsby";
import { calculateResponsiveSize } from "helpers/calculateResponsiveSize";
import { mediaMax } from "helpers/mediaQueries";
import LogoImage from "icons/horizontal-logo.svg";
import InstagramIcon from "icons/instagram-icon-black.svg";
import InstagramIconWhite from "icons/instagram-icon-white.svg";
import InstagramIconHover from "icons/instagram-icon-terracotta.svg";
import MenuIcon from "icons/menu-icon.svg";

export const Logo = styled(Link)`
  display: block;
  width: ${calculateResponsiveSize(563)};
  height: ${calculateResponsiveSize(74)};
  background: url(${LogoImage}) center center no-repeat;
  background-size: contain;
  transition: transform 0.5s;
  transform: translateY(-20vh);
`;

export const MenuWrapper = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  padding-left: ${calculateResponsiveSize(30)};

  &:before {
    content: "";
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #fff;
    z-index: -1;
    transition: width 0.5s;
  }
  ${mediaMax("desktopSm")} {
    padding-left: 0;
  }
`;

export const ButtonStyles = css`
  background-color: transparent;
  border: 0;
  cursor: pointer;
`;

export const TextButtonStyles = css`
  margin: ${calculateResponsiveSize(10)} ${calculateResponsiveSize(10)} 0 0;
  display: flex;
  flex-direction: column;
  font-family: "caslon_semibold", san-serif;
  font-size: ${calculateResponsiveSize(18)};
  line-height: ${calculateResponsiveSize(22)};
  text-transform: uppercase;
  color: inherit;
  font-variant: all-small-caps;
  letter-spacing: ${calculateResponsiveSize(2)};
  &:hover {
    &:after {
      width: 100%;
    }
  }
  &:after {
    content: "";
    display: block;
    height: 1px;
    width: 0;
    transition: width 0.5s;
  }

  ${mediaMax("desktopSm")} {
    font-size: 18px;
    line-height: 22px;
  }
`;

export const MenuTextButton = styled.button`
  ${ButtonStyles};
  ${TextButtonStyles};
  ${mediaMax("desktopSm")} {
    display: none;
  }
`;

export const MenuTextLink = styled(Link)`
  ${ButtonStyles};
  ${TextButtonStyles};
  ${mediaMax("desktopSm")} {
    display: none;
  }
`;

export const MenuSocialButton = styled.a`
  ${ButtonStyles};
  margin: 0 ${calculateResponsiveSize(24)} 0 ${calculateResponsiveSize(12)};
  width: ${calculateResponsiveSize(18)};
  height: ${calculateResponsiveSize(18)};
  background: url(${InstagramIcon}) no-repeat;
  transition: background-image 0.5s;
  background-size: contain;
  &:hover {
    background-image: url(${InstagramIconHover});
  }
  ${mediaMax("desktopSm")} {
    /* margin: 0 24px 0 12px;
    width: 18px;
    height: 18px; */
    display: none;
  }
  ${mediaMax("tabletLandscape")} {
  }
`;

export const MenuButton = styled.button`
  position: relative;
  ${ButtonStyles};
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${calculateResponsiveSize(84)};
  height: ${calculateResponsiveSize(84)};
  background-color: #d77e5d;
  overflow: hidden;

  &:before {
    content: "";
    position: relative;
    display: block;
    width: ${calculateResponsiveSize(36)};
    height: ${calculateResponsiveSize(9)};
    background-image: url(${MenuIcon});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 1;
  }
  &:hover {
    &:after {
      width: 100%;
    }
  }
  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 0;
    background-color: #1e1e1e;
    transition: width 0.5s;
  }
  ${mediaMax("desktopSm")} {
    /* margin: 0 24px 0 12px; */
    width: 84px;
    height: 84px;
    &:before {
      width: 36px;
      height: 9px;
    }
  }
  ${mediaMax("tabletLandscape")} {
    width: 36px;
    height: 36px;
    &:before {
      width: 18px;
      height: 4.5px;
      background-size: contain;
    }
  }
`;

export const Footer = styled.footer``;

export const Wrapper = styled.header`
  position: fixed;
  right: 0;
  top: ${calculateResponsiveSize(60)};
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: max-content;
  padding: 0 ${calculateResponsiveSize(60)};
  z-index: 999;

  &.home {
    ${MenuWrapper} {
      opacity: 0;
      &:before {
        width: 0;
      }
    }
    ${({ $state }) => {
      switch ($state) {
        case "firstStep":
          return `
           ${MenuSocialButton}{
            background: url(${InstagramIconWhite}) no-repeat;
            background-size: cover;
          }
       
          ${MenuTextButton} {
            color: #fff;
          }
          ${MenuTextLink} {
            color: #fff;
          }
          ${MenuWrapper} {
            opacity: 1;
            &:before {width:0;}
          }
        `;
        case "secondStep":
          return `
          ${MenuTextButton} {
            color: #1E1E1E;
          }
          ${MenuTextLink} {
            color: #1E1E1E;
          }
          ${MenuWrapper} {
            opacity: 1;
            &:before {width:100%;}
          }
 
        `;
        default:
          return `
         
        `;
      }
    }}
  }
  ${mediaMax("tabletLandscape")} {
    top: 20px;
    right: 20px;
    padding: 0;
  }
`;
