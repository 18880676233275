import React from "react";
import {
  Row,
  Wrapper,
  Logos,
  LogoHines,
  LogoTCC,
  LegalLink,
  Copyright,
} from "./index.styled";

const CopyrightAndLegal = ({ onClick, className, link, text }) => {
  return (
    <Wrapper>
      <Row>
        <Logos>
          <LogoHines />
          <LogoTCC />
        </Logos>
      </Row>
      <Row>
        <Copyright>
          The Ritz-Carlton Residences, Boston, South Station Tower are not
          owned, developed or sold by The Ritz-Carlton Hotel Company, L.L.C. Or
          its affiliates (“Ritz-Carlton”). <br /> South Station Phase I Owner
          LLC uses The Ritz-Carlton marks under a license from Marriott
          International, Inc. Which has not confirmed the accuracy of any of the
          statements or representations made herein.
        </Copyright>
        <LegalLink onClick={onClick} to="/legal">
          Legal
        </LegalLink>
      </Row>
    </Wrapper>
  );
};

export default CopyrightAndLegal;
