import React from "react";
import { Button } from "./index.styled";

const CloseButton = ({ onClick }) => {
  return (
    <>
      <Button onClick={onClick} />
    </>
  );
};

export default CloseButton;
