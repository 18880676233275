import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`

    li {
        list-style: none;
    }
    a {
      text-decoration: none;
      color: inherit;
    }
    * {
      margin: 0;
      padding: 0;
      font-family: inherit;
      box-sizing: border-box;
      font-family: 'caslon', sans-serif;
    }
     body {
         &.overflow {
        overflow: hidden;
      }
    }
    .ReactModal__Overlay {
    opacity: 0;
    transition: opacity 500ms ease-in-out;
    }

    .ReactModal__Overlay--after-open{
        opacity: 1;
    }

    .ReactModal__Overlay--before-close{
        opacity: 0;
    }

  .display-flex-mobile {
    display: none !important;
  }
  .display-block-mobile {
    display: none !important;
  }
  @media (max-width: 1023px) { 

    .display-desktop {
        display: none !important;
      }
      .display-flex-mobile {
        display: flex !important;
      }
      .display-block-mobile {
        display: block !important;
      }
    }
    @media (max-width: 1023px) {
      .gatsby-image-wrapper {
        aspect-ratio: 4/3;
      }
    }
`;

export default GlobalStyle;
